import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Blocks from 'editorjs-blocks-react-renderer';
import parse from 'html-react-parser';

import { Heading } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const formatWrapperID = (heading) => {
  if (!heading) {
    return '';
  }
  // remove special characters and spaces and make it lowercase
  return heading.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
};

const SectionFormattedTextMaybe = props => {
  const {
    text: text_raw,
    heading,
    showAsIngress = false,
    isCollapsible = false,
    elementId = null,
  } = props;

  const edjs_obj = text_raw ? JSON.parse(text_raw) : null;
  // const textClass = showAsIngress ? css.ingress : css.text;
  // const content = richText(text, {
  //   longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
  //   longWordClass: css.longWord,
  //   breakChars: '/',
  // });

  if (!edjs_obj?.blocks || edjs_obj.blocks.length === 0) {
    return null;
  }

  /*
  let output = [];
  if (edjs_obj?.blocks) {
    for (let i = 0; i < edjs_obj.blocks.length; i++) {
      const curr_block = edjs_obj.blocks[i];
      const {
        type,
        data,
      } = curr_block || {};
      if (type === 'paragraph') {
        output.push((
          <p key={i}>{data?.text}</p>
        ));
      } else if (type === 'bold' && data?.level === 1) {
        output.push((
          <b key={i}>{data?.text}</b>
        ));
      } else if (type === 'header' && data?.level === 1) {
        output.push((
          <h1 key={i}>{data?.text}</h1>
        ));
      } else if (type === 'header' && data?.level === 2) {
        output.push((
          <h2 key={i}>{data?.text}</h2>
        ));
      } else if (type === 'header' && data?.level === 3) {
        output.push((
          <h3 key={i}>{data?.text}</h3>
        ));
      } else if (type === 'header' && data?.level === 4) {
        output.push((
          <h4 key={i}>{data?.text}</h4>
        ));
      } else if (type === 'header' && data?.level === 5) {
        output.push((
          <h5 key={i}>{data?.text}</h5>
        ));
      } else if (type === 'list' && data?.style === 'unordered') {
        output.push((
          <ul key={i} className={css.ul}>
            {data?.items.map((li, j) => (
              <li key={j}>{li}</li>
            ))}
          </ul>
        ));
      }
    }
  }
  */

  const cleanedChars = (text) => {
    if (!text) {
      return '';
    }
    return text.replaceAll(String.fromCharCode(65310),String.fromCharCode(62))
      .replaceAll(String.fromCharCode(65308),String.fromCharCode(60));
  }
  const Paragraph = ({
    data,
    className = '',
  }) => {
    return (
      <>
        <p className={className}>
          {parse(cleanedChars(data?.text || ''))}
        </p>
      </>
    )
  }

  const formattedOutput = (
    <Blocks
      data={ edjs_obj }
      renderers={{
        paragraph: Paragraph,
      }}
    />
  );

  if (!isCollapsible) {
    return (
      <div className={css.sectionText}>
        {heading ? (
          <Heading as="h2" rootClassName={css.sectionHeading}>
            {heading}
          </Heading>
        ) : null}
        {formattedOutput}
      </div>
    );
  }

  return (
    <div id={elementId || `section-text-${formatWrapperID(heading)}`} className={css.sectionText}>
      <Accordion
        elevation={0}
        className={css.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css.accordionSummary}
          classes={{
            content: css.accordionSummaryContent,
          }}
        >
          <Heading as="h2" rootClassName={css.sectionHeading}>
            {heading}
          </Heading>
        </AccordionSummary>
        <AccordionDetails className={css.accordionDetails}>
          {formattedOutput}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SectionFormattedTextMaybe;
