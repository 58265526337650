import React from 'react';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';

const SectionGallery = props => {
  const { listing, variantPrefix, selectedVariant } = props;
  const images = listing.images;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  const photo_variants_json = listing?.attributes?.publicData?.photo_variants || '';
  const photo_variants = photo_variants_json ? JSON.parse(photo_variants_json) : [];
  // console.log('selectedVariant', selectedVariant);
  // console.log('photo_variants', photo_variants);
  const filtered_images = Array.isArray(images) ? images.filter((i) => {
    if (!selectedVariant) {
      return true;
    }
    if (
      !photo_variants
    ) {
      return true;
    }
    const found_photo_variant = photo_variants.find((pv) => pv.id === i?.id?.uuid);
    if (!found_photo_variant) {
      return true;
    }
    let allow = true;
    let selected_variant_key = selectedVariant.value;
    if (selectedVariant.subvariant_value) {
      selected_variant_key = `${selectedVariant?.value}__${selectedVariant?.subvariant_value}`;
    }
    // console.log('selected_variant_key', found_photo_variant, selected_variant_key);
    if (
      Array.isArray(found_photo_variant?.variant)
      && found_photo_variant.variant.length > 0
      && !found_photo_variant.variant.includes(selected_variant_key)
    ) {
      allow = false;
    }
    return allow;
  }) : [];
  return (
    <div id="section-image-carousel" className={css.productGallery} data-testid="carousel">
      <ListingImageGallery
        images={filtered_images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
      />
    </div>
  );
};

export default SectionGallery;
